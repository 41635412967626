import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedNumber } from 'react-intl';
import { DataTable, Currency, YearMonth, TextEllipsis } from '../../components';
import CommissionTypeDisplay from './CommissionTypeDisplay';

import Api from '../api';

class PayoutDetailsDataTable extends PureComponent {
    static propTypes = {
        payoutMonth: PropTypes.string.isRequired,
        payoutTrackingCode: PropTypes.string.isRequired,
    };

    render() {
        const { intl, payoutMonth, trackingCode } = this.props;

        const columns = [
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_MONTH' }),
                dataIndex: 'calMonth',
                width: 80,
                render: (val) => <YearMonth value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TRACKING_CODE' }),
                dataIndex: 'trackingCode',
                width: 110,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_SUBID' }),
                dataIndex: 'subId',
                render: (val) => <TextEllipsis value={val} maxWidth={130} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_TYPE' }),
                dataIndex: 'detailType',
                render: (val, record) => <CommissionTypeDisplay value={record} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_ORDER' }),
                dataIndex: 'orderCount',
                render: (val) => <FormattedNumber value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_CANCEL' }),
                dataIndex: 'cancelCount',
                render: (val) => <FormattedNumber value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_PURCHASE_AMOUNT' }),
                dataIndex: 'totalActualPaymentAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_CANCEL_AMOUNT' }),
                dataIndex: 'totalCancelAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_TOTAL_AMOUNT' }),
                dataIndex: 'totalActualPaymentAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_COMMISSION' }),
                dataIndex: 'totalCommission',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
        ];

        const extraFilters = [
            { column: 'payoutMonth', operator: 'EQUAL', value: [payoutMonth] },
            { column: 'payoutTrackingCode', operator: 'EQUAL', value: [trackingCode] },
        ];

        return (
            <DataTable
                rowKey="settlementDetailId"
                columns={columns}
                extraFilters={extraFilters}
                getData={Api.fetchPayoutDetailsReport}
            />
        );
    }
}

export default injectIntl(PayoutDetailsDataTable);
