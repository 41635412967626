import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import HomeIcon from 'react-feather/dist/icons/home';
import RemoveIcon from 'react-feather/dist/icons/trash-2';

import { Button } from 'antd';

import { WorkspacePage, CpRow, DataTable, IntlDate } from '../../components';
import Api from '../api';
import DeleteSubIdModal from '../components/DeleteSubIdModal';
import CreateSubIdModal from '../components/CreateSubIdModal';

export default function SubIds() {
    const intl = useIntl();
    const history = useHistory();
    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'SUB_ID_TITLE' }) },
    ];

    const tableRef = useRef();
    const refresh = () => {
        if (tableRef.current) {
            tableRef.current.refresh();
        }
    };
    const gotoFaq = () => {
        sessionStorage.setItem('faqDefaultActiveKey', '6');
        history.push('/help/faq');
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'SUB_ID' }),
            dataIndex: 'subId',
        },
        {
            title: intl.formatMessage({ id: 'CREATE_METHOD' }),
            dataIndex: 'sourceType',
            render: (val) => {
                return val === 'INTERNAL' ? 'UI' : val === 'EXTERNAL' ? 'API' : val;
            },
        },
        {
            title: intl.formatMessage({ id: 'DELETED' }),
            dataIndex: 'deleted',
            align: 'center',
            render: (val) => (val ? 'Y' : 'N'),
        },
        {
            title: intl.formatMessage({ id: 'CREATED_DATE' }),
            dataIndex: 'createdDate',
            render: (val) => <IntlDate value={val} />,
        },
        {
            title: intl.formatMessage({ id: 'LAST_MODIFIED' }),
            dataIndex: 'modifiedDate',
            render: (val) => <IntlDate value={val} />,
        },
    ];

    columns.push({
        title: intl.formatMessage({ id: 'ACTIONS' }),
        align: 'center',
        render: (_, record) => {
            return record.deleted ? null : (
                <React.Fragment>
                    <DeleteSubIdModal subId={record.subId} onSuccess={refresh}>
                        {({ open }) => <RemoveIcon size={18} className="clickable" color="#aeb0b4" onClick={open} />}
                    </DeleteSubIdModal>
                </React.Fragment>
            );
        },
    });

    return (
        <WorkspacePage
            breadcrumb={breadcrumb}
            title={intl.formatMessage({ id: 'SUB_ID_TITLE' })}
            subTitle={intl.formatMessage(
                { id: 'SUB_ID_SUB_TITLE' },
                {
                    help: (
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                gotoFaq();
                            }}
                            href="">
                            {intl.formatMessage({ id: 'HELP' })}
                        </a>
                    ),
                }
            )}>
            <CpRow>
                <div className="align-right" style={{ marginBottom: 15 }}>
                    <CreateSubIdModal onSuccess={refresh}>
                        {({ open }) => (
                            <Button type="primary" className="lg" style={{ minWidth: 160 }} icon="plus" onClick={open}>
                                {intl.formatMessage({ id: 'SUB_ID_CREATE_MODAL_TITLE' })}
                            </Button>
                        )}
                    </CreateSubIdModal>
                </div>
                <DataTable onRef={ref=>tableRef.current = ref} rowKey="subId" columns={columns} getData={Api.getSubIds} />
            </CpRow>
        </WorkspacePage>
    );
}
