import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Tooltip, Icon } from 'antd';
import { withRouter } from 'react-router';
import { DataTable, Currency, YearMonth, IntlDate } from '../../components';
import PayStatus from './PayStatus';
import Api from '../api';

class PayoutReportIndividual extends PureComponent {
    viewPayoutSettlements = (trackingCode, settleMonth) => {
        const { history } = this.props;
        history.push(`/affiliate/ws/report/payout/${trackingCode}/${settleMonth}`);
    };

    render() {
        const { intl } = this.props;

        const columns = [
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_MONTH' }),
                dataIndex: 'settleMonth',
                width: 80,
                render: (val) => <YearMonth value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TRACKING_CODE' }),
                dataIndex: 'trackingCode',
                width: 110,
            },
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_TOTAL_AMOUNT' }),
                dataIndex: 'totalAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: (
                    <div>
                        <span>{intl.formatMessage({ id: 'SETTLEMENT_COL_WITHHOLDING_TAX' })}</span>
                        <Tooltip
                            title="수익에 부과되는 사업소득세(3%) 및 주민세(사업소득세의 10%)를 합친 금액입니다.​"
                            trigger="click"
                        >
                            <Icon type="question-circle" style={{ marginLeft: '0.5em', fontSize: '1.2em' }} />
                        </Tooltip>
                    </div>
                ),
                align: 'right',
                render: (val, record) => <Currency value={record.incomeTax + record.localTax} />,
            },
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_AMOUNT_TO_PAY' }),
                dataIndex: 'payAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: (
                    <React.Fragment>
                        <span>{intl.formatMessage({ id: 'TAX_INVOICE_COL_PAYMENT_DATE' })}</span>
                        <Tooltip title={intl.formatMessage({ id: 'PAYMENT_DATE_TIPS' })} trigger="click">
                            <Icon type="question-circle" style={{ marginLeft: '0.5em', fontSize: '1.2em' }} />
                        </Tooltip>
                    </React.Fragment>
                ),
                dataIndex: 'payDate',
                align: 'center',

                render: (val) => <IntlDate value={val} />,
            },

            {
                title: intl.formatMessage({ id: 'PAY_STATUS' }),
                dataIndex: 'payStatus',
                render: (payStatus, record) => {
                    return <PayStatus payStatus={payStatus} payStatusString={record.payStatusString} />;
                },
            },
            {
                title: '',
                dataIndex: 'details',
                width: 80,
                render: (val, record) => {
                    return (
                        <Button
                            disabled={val === 0}
                            className="sm"
                            onClick={() => this.viewPayoutSettlements(record.trackingCode, record.settleMonth)}
                        >
                            {intl.formatMessage({ id: 'VIEW_DETAILS' })}
                        </Button>
                    );
                },
            },
        ];

        return <DataTable rowKey="monthlySettlementPayoutId" columns={columns} getData={Api.fetchPayoutReport} />;
    }
}

export default injectIntl(withRouter(PayoutReportIndividual));
