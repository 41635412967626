import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Form, Input, Checkbox, Typography } from 'antd';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import cx from 'classnames';

import { ImageModalViewer, CheckableTagsInput, EasyUrlsInput, TipText } from '../../components';
import RegistrationApi from '../api';
import screenshotExample from './screenshot-example.jpg';

import './ProfileForm.css';

const isListChanged = (o, b) => {
    let changed = false;
    if (o.length !== b.length) {
        changed = true;
    } else {
        for (let x of b) {
            if (!o.find((y) => y.url === x.url && !!y.ownership === !!x.ownership)) {
                changed = true;
                break;
            }
        }
    }
    return changed;
};

export class Profile extends Component {
    regUrl = new RegExp(
        '^(?:(?:http|https)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
        'i'
    );
    regAppleAppUrl = new RegExp('^(?:http|https)://itunes.apple.com/(kr|us)/app/\\w+', 'i');
    regAndroidAppUrl = new RegExp('^(?:http|https)://play.google.com/store/apps/\\w+', 'i');

    static propTypes = {
        isReapply: PropTypes.bool,
        defaultValues: PropTypes.object,
        disabled: PropTypes.bool,
        data: PropTypes.object.isRequired,
        action: PropTypes.oneOf(['create', 'edit']).isRequired,
    };

    state = {
        websitesHaveBeenChanged: false,
        appsHaveBeenChanged: false,
        affiliateCodeValidateStatus: undefined,
    };

    validate() {
        let { form } = this.props;
        return new Promise((resolve, reject) => {
            form.validateFields((errors, values) => {
                if (errors) {
                    reject(errors);
                } else {
                    resolve(this.normalizeFormData(values));
                }
            });
        });
    }

    getValue() {
        return this.normalizeFormData(this.props.form.getFieldsValue());
    }

    normalizeFormData(obj) {
        return {
            // affiliateCode: obj.affiliateCode,
            // termAndConditions: consentItems.map(c => c.key),
            affiliateType: obj.affiliateType,
            websites: obj.websites.map((x) => ({
                name: x.url,
                ownership: !!x.ownership,
                snapshot: x.snapshot,
            })),
            apps: obj.apps.map((x) => ({
                name: x.url,
                ownership: !!x.ownership,
                snapshot: x.snapshot,
            })),
        };
    }

    validateUrls = (value, ...regs) => {
        const urls = value || [];
        let validCount = 0;
        for (let url of urls) {
            let hasMatch = regs.find((reg) => reg.test(url));
            if (hasMatch) {
                validCount++;
            }
        }

        if (validCount === urls.length) {
            return true;
        } else {
            return false;
        }
    };

    validateWebsiteUrl = (url) => {
        return new Promise((resolve, reject) => {
            const urlValid = this.validateUrls([url], this.regUrl);
            if (!urlValid) {
                const { intl } = this.props;
                return reject(intl.formatMessage({ id: 'REGISTRATION_PROFILE_MSG_INVALID_SITE' }));
            }
            RegistrationApi.validateUrl('WEB', url)
                .then((res) => {
                    const { ownership } = res.data.data;
                    resolve({ url, ownership });
                })
                .catch((err) => {
                    const { rMessage } = err.data;
                    reject(rMessage || 'Url not allowed!');
                });
        });
    };

    validateAppUrl = (url) => {
        return new Promise((resolve, reject) => {
            // const urlValid = this.validateUrls([url], this.regAppleAppUrl, this.regAndroidAppUrl);
            const urlValid = this.validateUrls([url], this.regUrl);
            if (!urlValid) {
                const { intl } = this.props;
                return reject(intl.formatMessage({ id: 'REGISTRATION_PROFILE_MSG_INVALID_APP' }));
            }
            RegistrationApi.validateUrl('APP', url)
                .then((res) => {
                    const { ownership } = res.data.data;
                    resolve({ url, ownership });
                })
                .catch((err) => {
                    const { rMessage } = err.data;
                    reject(rMessage || 'Url not allowed!');
                });
        });
    };

    checkWebsiteEntered = (rule, value, callback, source, options) => {
        const { intl, form } = this.props;

        if (value.error) {
            return callback(value.error);
        }

        if (!value.length) {
            const apps = options.mobile || form.getFieldValue('apps');
            if (!apps || !apps.length) {
                callback(intl.formatMessage({ id: 'REGISTRATION_PROFILE_MSG_ENTER_WEBSITE_OR_APP' }));
            }
        }
        if (value.length && !options.force) {
            form.validateFields(['apps'], { force: true, websites: value });
        }
        callback();
    };

    checkMobileAppEntered = (rule, value, callback, source, options) => {
        const { intl, form } = this.props;

        if (value.error) {
            return callback(value.error);
        }

        if (!value.length) {
            const websites = options.websites || form.getFieldValue('websites');
            if (!websites || !websites.length) {
                callback(intl.formatMessage({ id: 'REGISTRATION_PROFILE_MSG_ENTER_WEBSITE_OR_APP' }));
            }
        }
        if (value.length && !options.force) {
            form.validateFields(['websites'], { force: true, mobile: value });
        }
        callback();
    };

    validateTopics = (rule, value, callback) => {
        const { intl } = this.props;
        if (value && value.selectedTags && value.selectedTags.length) {
            callback();
        } else {
            callback(intl.formatMessage({ id: 'REGISTRATION_PROFILE_MSG_SELECT_AT_LEAST_ONE_TOPIC' }));
        }
    };

    validateConsent = (rule, value, callback) => {
        const { intl } = this.props;
        if (value) {
            callback();
        } else {
            callback(intl.formatMessage({ id: 'CONSENT_WHEN_YOUR_CHANGE_SITES' }));
        }
    };

    validateConfirm = (rule, value, callback) => {
        const { intl } = this.props;
        if (value) {
            callback();
        } else {
            callback(intl.formatMessage({ id: 'PLEASE_AGREE_ALL' }));
        }
    };

    normalizeUrl = (text, https) => {
        let url = text.toLowerCase();
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `http${https ? 's' : ''}://${url}`;
        }
        return text;
    };

    normalizeHttpsUrl = (text) => {
        return this.normalizeUrl(text, true);
    };

    onWebsitesChange = (list) => {
        if (Array.isArray(list)) {
            let changed = isListChanged(this.props.data.websites, list);

            this.setState({
                websitesHaveBeenChanged: changed,
            });
        }
    };

    onAppsChange = (list) => {
        let changed = isListChanged(this.props.data.apps, list);

        this.setState({
            appsHaveBeenChanged: changed,
        });
    };

    render() {
        const {
            intl,
            form: { getFieldDecorator },
            disabled,
            data,
            action,
            isReapply,
            defaultValues: { affiliateType: fromAffiliateType } = {},
            affiliateType,
        } = this.props;
        const { websitesHaveBeenChanged, appsHaveBeenChanged, affiliateCodeValidateStatus } = this.state;
        const rs = { Add: intl.formatMessage({ id: 'ADD' }) };
        // const longTagStyle = { maxWidth: 420, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' };
        const isCreateMode = action === 'create';

        return (
            <div className="profile">
                <Form layout="vertical" hideRequiredMark={false}>
                    <div className="mb16">
                        <Typography.Text strong={true}>
                            {intl.formatMessage({ id: 'MY_ACCOUNT_PROFILE_TIPS' })}
                        </Typography.Text>
                    </div>
                    <div className="mb16">
                        <TipText className="mb6">
                            {intl.formatMessage({ id: 'MY_ACCOUNT_PROFILE_TIPS_TEXT_1' })}
                        </TipText>
                        <TipText>{intl.formatMessage({ id: 'MY_ACCOUNT_PROFILE_TIPS_TEXT_2' })}</TipText>
                    </div>
                    <div>
                        <Form.Item label={intl.formatMessage({ id: 'REGISTRATION_PROFILE_WEBSITES' })}>
                            {getFieldDecorator('websites', {
                                initialValue: data.websites,
                                rules: [{ validator: this.checkWebsiteEntered }],
                            })(
                                <EasyUrlsInput
                                    disabled={disabled}
                                    inputMaxLength={1000}
                                    uploadScreenshot={!isCreateMode || isReapply}
                                    rs={{
                                        add: intl.formatMessage({ id: 'ADD_URL' }),
                                        owner: intl.formatMessage({ id: 'REGISTRATION_PROFILE_OWNER' }),
                                        ownerTooltip: intl.formatMessage({ id: 'REGISTRATION_PROFILE_OWNER_TOOLTIP' }),
                                        placeholder: 'https://',
                                    }}
                                    validateFunc={this.validateWebsiteUrl}
                                    onChange={this.onWebsitesChange}
                                />
                            )}
                        </Form.Item>
                        <MediaQuery maxWidth={516}>
                            {(matches) => {
                                let placeholder = intl.formatMessage({ id: 'REGISTRATION_PROFILE_APPS_PLACEHOLDER' });
                                if (matches) {
                                    placeholder = intl.formatMessage({
                                        id: 'REGISTRATION_PROFILE_APPS_SHORT_PLACEHOLDER',
                                    });
                                }
                                return (
                                    <Form.Item label={intl.formatMessage({ id: 'REGISTRATION_PROFILE_APPS' })}>
                                        {getFieldDecorator('apps', {
                                            initialValue: data.apps,
                                            rules: [{ validator: this.checkMobileAppEntered }],
                                        })(
                                            <EasyUrlsInput
                                                disabled={disabled}
                                                inputMaxLength={1000}
                                                uploadScreenshot={!isCreateMode}
                                                inputClassName={cx({ 'min-placeholder': matches })}
                                                rs={{
                                                    placeholder,
                                                    add: intl.formatMessage({ id: 'ADD_URL' }),
                                                }}
                                                validateFunc={this.validateAppUrl}
                                                onChange={this.onAppsChange}
                                            />
                                        )}
                                    </Form.Item>
                                );
                            }}
                        </MediaQuery>
                    </div>
                    {action === 'edit' && (websitesHaveBeenChanged || appsHaveBeenChanged) && (
                        <Form.Item>
                            {getFieldDecorator('consent', {
                                valuePropName: 'checked',
                                initialValue: false,
                                rules: [{ validator: this.validateConsent }],
                            })(
                                <Checkbox>
                                    {intl.formatMessage({ id: 'REGISTRATION_PROFILE_MSG_MODIFY_SITE_APP' })}​
                                </Checkbox>
                            )}
                        </Form.Item>
                    )}

                    <div className="mb16">
                        <Typography.Text strong={true}>
                            {intl.formatMessage({ id: 'REGISTRATION_UPLOAD_SCREENSHOTS_TIPS' }, { br: <br /> })}
                        </Typography.Text>
                    </div>
                    <div className="is-flex-row">
                        {isCreateMode && (
                            <Form.Item className="is-flex">
                                {getFieldDecorator('confirm', {
                                    valuePropName: 'checked',
                                    initialValue: false,
                                    rules: [{ validator: this.validateConfirm }],
                                })(<Checkbox>{intl.formatMessage({ id: 'CHECKBOX_CONFIRMED' })}​</Checkbox>)}
                            </Form.Item>
                        )}
                        <ImageModalViewer>
                            {({ openModal }) => (
                                <span
                                    className="clickable color-primary ft-12 text-bold fr is-flex-row mb32"
                                    onClick={() => openModal(screenshotExample)}
                                >
                                    {intl.formatMessage({ id: 'EXAMPLES' })} <ChevronRight size={16} />
                                </span>
                            )}
                        </ImageModalViewer>
                    </div>

                    <Form.Item>
                        <div className="dark-grey-box">{intl.formatMessage({ id: 'REGISTRATION_CHANNEL_TIPS' })}</div>
                    </Form.Item>

                    <Form.Item>
                        <div className="dark-grey-box">
                            {intl.formatMessage({ id: 'REGISTRATION_COMMISSION_CAP_TIPS' })}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const IntlProfile = injectIntl(Profile, { forwardRef: true });
export default Form.create()(IntlProfile);
