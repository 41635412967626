import React, { useContext } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { LocaleContext } from '../Locale';
import { MetricList } from './Metric';

export default function ({ metric, value, className }) {
    const intl = useIntl();
    const { currency } = useContext(LocaleContext);
    const options = metric.options || MetricList.find((item) => item.id === metric).options;
    if (options && options.style === 'currency') {
        options.currency = currency;
    }
    return <span className={cx('metric-value', className)}>{intl.formatNumber(value || 0, options)}</span>;
}
