import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CardPage } from '../../components';
import { Consts } from '../../common';
import {
    BusinessPayerInformationFieldset,
    CompanyInformationFieldset,
    ContactFieldset,
    BankAccountFieldset,
    PaymentModificationTips,
    DocumentFieldset,
} from '../components';
import { validateBRN, validateBankAccount, fetchEditBusinessPaymentInitData, updateBusinessPayment } from '../actions';

import SettlementApi from '../../settlement/api';

const mapStateToProps = (state) => ({
    bankNameAndCodes: state.payment.bankNameAndCodes,
    data: state.payment.data,
    loading: state.payment.loading,
    affiliateType: state.affiliate.affiliateType,
    activated: state.affiliate.activateStatus === Consts.ActivateStatus.Activated,
    maintainingEnabled: !!state.config.maintainingEnabled,
});

const mapDispatchToProps = {
    validateBRN,
    validateBankAccount,
    fetchEditBusinessPaymentInitData,
    updateBusinessPayment,
};

class EditBusinessPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { fetchEditBusinessPaymentInitData, form } = this.props;
        if (fetchEditBusinessPaymentInitData) {
            fetchEditBusinessPaymentInitData();
        }

        SettlementApi.getDocStatuses().then((response) => {
            const { data: { hasBrcDoc, hasBankbookDoc } = {} } = response.data;

            let brnFileList = [];
            let bankbookFileList = [];
            if (hasBrcDoc) {
                brnFileList = [{ uid: '-1', status: 'done', url: `/api/v1/payment/image/brn` }];
            }
            if (hasBankbookDoc) {
                bankbookFileList = [{ uid: '-2', status: 'done', url: `/api/v1/payment/image/bank` }];
            }
            this.setState({ docStatusesChecked: true }, () => {
                form.setFieldsValue({ brnFileList, bankbookFileList });
            });
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { history, form, updateBusinessPayment, affiliateType } = this.props;

        form.validateFields({}, (err, values) => {
            if (!err) {
                values.affiliateType = affiliateType;
                values.brn = values.brn.raw;
                values.address = values.address || '';
                values.mobile = values.mobile || '';

                if (updateBusinessPayment) {
                    updateBusinessPayment(values).then(() => {
                        history.push('/affiliate');
                    });
                }
            }
        });
    };

    render() {
        const {
            intl,
            form,
            loading,
            maintainingEnabled,
            activated,
            bankNameAndCodes,
            data,
            validateBRN,
            validateBankAccount,
        } = this.props;

        return (
            <div className="edit-business-payment">
                <CardPage loading={loading}>
                    <Form layout="vertical" hideRequiredMark={false}>
                        <div className="mb24">
                            <PaymentModificationTips />
                        </div>
                        <BusinessPayerInformationFieldset
                            form={form}
                            data={data}
                            validateBRN={validateBRN}
                            disabled={activated}
                        />

                        <CompanyInformationFieldset form={form} data={data} disabled={activated} />

                        <ContactFieldset
                            mode="edit"
                            form={form}
                            data={data}
                            disabled={maintainingEnabled}
                            includeContactName={true}
                        />

                        {/* <BankAccountFieldset type="BUSINESS" form={form} data={data} disabled={maintainingEnabled || ['waiting', 'approved', 'rejected'].includes(affiliatePaymentStatus)} banks={bankNameAndCodes} validateBankAccount={validateBankAccount} /> */}
                        {/* disabled={true}  this means to business affiliate after input payment info can't edit anymore */}
                        <BankAccountFieldset
                            type="BUSINESS"
                            form={form}
                            data={data}
                            disabled={activated}
                            banks={bankNameAndCodes}
                            validateBankAccount={validateBankAccount}
                        />

                        <DocumentFieldset form={form} disabled={activated} />

                        {!maintainingEnabled && (
                            <div className="button-bar align-center">
                                <Button size="large" type="primary" htmlType="button" onClick={this.handleSubmit}>
                                    {intl.formatMessage({ id: 'SAVE' })}
                                </Button>
                            </div>
                        )}
                    </Form>
                </CardPage>
            </div>
        );
    }
}

export default Form.create()(injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditBusinessPayment))));
