import React from 'react';
import { useIntl } from 'react-intl';
import { useFetch } from '../../../common';
import MetricValue from '../../../components/Metric/MetricValue';
import Api from '../../api';
import styles from './CommissionCapNotification.module.less';

export default function CommissionCapNotification() {
    const { data } = useFetch(() => Api.fetchCommissionCapNotification());
    const intl = useIntl();
    return (
        // <div style={{border:'1px solid #F0F0F0',borderRadius:8,padding:24,marginBottom:40}}>
        data ? (
            <div className={styles.box}>
                {intl.formatMessage(
                    { id: 'EARNING_NOTIFICATION' },
                    {
                        br: <br />,
                        commissionCap: (
                            <span className={styles.value}>
                                <MetricValue metric="commissionCap" value={data.commissionCap} />
                            </span>
                        ),
                        remainingCap: (
                            <span className={styles.value}>
                                <MetricValue metric="remainingCap" value={data.remainingCap} />
                            </span>
                        ),
                        estimatedCapSpendingRate: (
                            <span className={styles.value}>
                                <MetricValue metric="estimatedCapSpendingRate" value={data.estimatedCapSpendingRate} />
                            </span>
                        ),
                        avgDailyCommission: (
                            <span className={styles.value}>
                                <MetricValue metric="avgDailyCommission" value={data.avgDailyCommission} />
                            </span>
                        ),
                    }
                )}
            </div>
        ) : null
    );
}
