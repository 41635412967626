import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import axios from 'axios';
import { setup } from './setup';
import getReducers from './reducers';

function buildStore() {
    const initialStore = {
        config: {
            locale: 'ko',
            ...window.cpapp.config,
        },
        session: { ...window.cpapp.session, isMobile: window.navigator.userAgent.indexOf('Mobile') > -1 },
    };

    const store = createStore(getReducers(), initialStore, applyMiddleware(reduxThunk));
    window.store = store;

    setup(store);
    return store;
}

function getStore() {
    return axios.get('/api/v1/config').then((res) => {
        (function (ctx) {
            window.config = ctx;
            var cpapp = (window.cpapp = {
                settings: ctx.settings,
                member: ctx.member,
                affiliate: ctx.affiliate,
                webAccessibleStatus: ctx.webAccessibleStatus,
                config: {
                    assetUrl: "{{assetUrl '<%=htmlWebpackPlugin.options.data.appRootName%>'}}",
                    loginUrl: ctx.settings.loginUrl,
                    logoutUrl: ctx.settings.logoutUrl,
                    imageCdnUrl: ctx.settings.imageCdnUrl,
                    staticImageCDN: ctx.settings.staticImageCDN,
                    maintainingEnabled: ctx.settings.maintainingEnabled,
                    maintainingMessage: ctx.settings.maintainingMessage,
                    widgetServerHost: ctx.settings.widgetServerHost,
                    widgetEnabled: ctx.settings.widgetEnabled,
                    widgetBannerEnabled: ctx.settings.widgetBannerEnabled,
                    openApiEnabled: ctx.settings.openApiEnabled,
                    passbackEnabled: ctx.settings.passbackEnabled,
                    mixedTypeAffiliate: ctx.settings.mixedTypeAffiliate,
                    affiliateTypeChange: ctx.settings.affiliateTypeChange,
                    categories: ctx.categories,
                    deliveryTypeImages: ctx.deliveryTypeImages,
                    advertisementLegalMessage: ctx.settings.advertisementLegalMessage,
                    coupangCEO: ctx.settings.coupangCEO,
                    email: ctx.settings.email,
                    memberBlocked: ctx.settings.memberBlocked,
                },
                session: {},
                subIds: ctx.subIds,
            });
            var member = ctx.member;
            var affiliate = ctx.affiliate;
            if (member) {
                cpapp.session.authenticated = member.valid;
                cpapp.session.username = member.userName;
                cpapp.session.email = member.email;
                cpapp.session.simulated = member.simulated;
            }
            if (affiliate) {
                cpapp.session.registrationStatus = affiliate.registrationStatus;
                cpapp.session.regFinalStatus = affiliate.regFinalStatus;
                cpapp.session.affiliateId = affiliate.affiliateId;
            }
        })(res.data.data);

        return buildStore();
    });
}

export default getStore;
