import React, { useImperativeHandle, useRef, useContext, forwardRef } from 'react';
import { Input } from 'antd';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input/mobile';
import flags from 'react-phone-number-input/flags';
import { CoreDataContext } from '../../components';

import 'react-phone-number-input/style.css';

const MyInputComponent = forwardRef((props, ref) => {
    const inputRef = useRef(null);
    useImperativeHandle(ref, () => inputRef.current.input, []);
    return <Input {...props} ref={inputRef} />;
});

const PhoneNumberInput = function (props) {
    const { settings } = useContext(CoreDataContext);
    const countries =
        settings.phoneNumberCountryList && Array.isArray(settings.phoneNumberCountryList)
            ? settings.phoneNumberCountryList.map((item) => item.country)
            : [];
    const countryDefault = settings.phoneNumberCountryDefault;
    return (
        <PhoneInput
            flags={flags}
            international
            countries={countries}
            defaultCountry={countryDefault}
            countryCallingCodeEditable={false}
            inputComponent={MyInputComponent}
            autoComplete="off"
            maxLength={30}
            {...props}
        />
    );
};

const usePhoneNumberValidator = () => {
    const { settings } = useContext(CoreDataContext);

    return (value) => {
        let x = isValidPhoneNumber(value);
        if (
            x &&
            settings.phoneNumberCountryList &&
            Array.isArray(settings.phoneNumberCountryList) &&
            settings.phoneNumberCountryList.length
        ) {
            const pn = parsePhoneNumber(value);
            if (Array.isArray(settings.phoneNumberCountryList)) {
                const pns = settings.phoneNumberCountryList.find((item) => item.country === pn.country);
                if (pns) {
                    x = pns.rules.every((item) => new RegExp(item).test(pn.nationalNumber));
                }
            }
        }
        return x;
    };
};

const validatePhoneNumber = function (value) {
    const x = isValidPhoneNumber(value);
    return x;
};

export { PhoneNumberInput, validatePhoneNumber, usePhoneNumberValidator };
