import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import { MetricSelect, MetricList, Metric, Chart } from '../../components';

class ClickAndPurchaseReport extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['daily', 'monthly']).isRequired,
        dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    state = {
        metric1: 'clickCount',
        metric2: 'commission',
    };

    onChangeMetric = (metric, value) => {
        this.setState({ [metric]: value });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.intl !== this.props.intl ||
            nextProps.type !== this.props.type ||
            nextProps.dataSource !== this.props.dataSource ||
            nextState.metric1 !== this.state.metric1 ||
            nextState.metric2 !== this.state.metric2
        );
    }

    render() {
        const { intl, type, hideMetrics, metrics, dataSource } = this.props;
        const { metric1, metric2 } = this.state;
        const m1 = MetricList.find((item) => item.id === metric1);
        const m2 = MetricList.find((item) => item.id === metric2);

        const xAxisData = dataSource.map((item) => item.key);
        const option = {
            grid: {
                bottom: '70',
            },
            legend: {
                data: [{ name: intl.formatMessage({ id: m1.key }) }, { name: intl.formatMessage({ id: m2.key }) }],
            },

            xAxis: {
                type: 'category',
                axisLabel: {
                    formatter: (value, index) => {
                        let date = new Date(value);
                        if (type === 'daily') {
                            if (index % 5 === 0) {
                                return moment(date).format('MM-DD');
                            }
                            return '';
                        } else if (type === 'monthly') {
                            let month = date.getMonth() + 1;
                            let year = date.getFullYear();
                            let useYear = false;
                            if (index > 0) {
                                if (new Date(xAxisData[index - 1]).getFullYear() !== year) {
                                    useYear = true;
                                }
                            } else {
                                useYear = true;
                            }

                            return useYear ? [month, year].join('\n\n') : month;
                        }

                        return value;
                    },
                },
                data: xAxisData,
            },
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        color: '#4AB475',
                        formatter: (value) => Metric.formatChartValue(value, m1, intl),
                    },
                    min: 0,
                },
                {
                    type: 'value',
                    axisLabel: {
                        color: '#8C50A3',
                        formatter: (value) => Metric.formatChartValue(value, m2, intl),
                    },
                    min: 0,
                },
            ],
            tooltip: {
                trigger: 'axis',
                formatter: Metric.makeChartTooltipFormatter([m1, m2], intl),
            },

            series: [
                {
                    name: intl.formatMessage({ id: m1.key }),
                    type: 'line',
                    yAxisIndex: 0,
                    itemStyle: {
                        color: '#4AB475',
                    },
                    data: dataSource.map((item) => item[metric1]),
                },
                {
                    name: intl.formatMessage({ id: m2.key }),
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        color: '#8C50A3',
                    },
                    data: dataSource.map((item) => item[metric2]),
                },
            ],
        };

        return (
            <React.Fragment>
                {!hideMetrics && (
                    <div className="action-group">
                        <div className="action">
                            <label>{intl.formatMessage({ id: 'METRIC' })}1:</label>
                            <MetricSelect
                                included={metrics}
                                disabledOptions={[metric2]}
                                value={metric1}
                                onChange={(value) => this.onChangeMetric('metric1', value)}
                            />
                        </div>
                        <div className="action">
                            <label>{intl.formatMessage({ id: 'METRIC' })}2:</label>
                            <MetricSelect
                                included={metrics}
                                disabledOptions={[metric1]}
                                value={metric2}
                                onChange={(value) => this.onChangeMetric('metric2', value)}
                            />
                        </div>
                    </div>
                )}
                <MediaQuery maxWidth={991}>
                    {(matches) => {
                        if (matches) {
                            option.yAxis[0].axisLabel.show = false;
                            option.yAxis[1].axisLabel.show = false;
                        } else {
                            option.yAxis[0].axisLabel.show = true;
                            option.yAxis[1].axisLabel.show = true;
                        }

                        if (xAxisData.length > 0) {
                            option.dataZoom = [
                                {
                                    type: 'inside',
                                    xAxisIndex: [0],
                                    startValue: xAxisData.slice(matches ? -10 : 0).shift(),
                                    endValue: xAxisData.slice(-1).pop(),
                                },
                            ];
                        }

                        return <Chart hasData={true} option={option} />;
                    }}
                </MediaQuery>
            </React.Fragment>
        );
    }
}

export default injectIntl(ClickAndPurchaseReport);
