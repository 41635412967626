import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Button, Tooltip, Icon, notification } from 'antd';
import * as moment from 'moment';
import { DataTable, Currency, Confirm, YearMonth, IntlDate } from '../../components';
import PayStatus from './PayStatus';
import Api from '../api';
import SettlementApi from '../../settlement/api';

class PayoutReportMixed extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pendingInvoice: null,
            showApprovalDialog: false,
        };
    }

    handleDataLoaded = (data, { pagination }) => {
        if (data && Array.isArray(data.content) && pagination.current === 1) {
            let pendingInvoice = data.content.find(
                (x) => x.settleMonth === moment().format('YYYYMM') && x.invSeq && x.invStatus === 'WAITING'
            );
            if (pendingInvoice) {
                this.setState({
                    pendingInvoice,
                    showApprovalDialog: true,
                });
            }
        }
    };

    viewPayoutSettlements = (trackingCode, settleMonth) => {
        const { history } = this.props;
        history.push(`/affiliate/ws/report/payout/${trackingCode}/${settleMonth}`);
    };

    openInvoiceApproveWindow = (settleMonth) => {
        SettlementApi.getInvoiceUrl(settleMonth)
            .then((res) => {
                if (res.data) {
                    window.open(res.data.data, '_blank');
                }
            })
            .catch((err) => {
                if (err.status === 200) {
                    notification.error({ message: 'Error', description: err.data ? err.data.rMessage : err.data });
                }
            });
    };

    onApprove = () => {
        this.setState({
            showApprovalDialog: false,
        });
        this.openInvoiceApproveWindow(this.state.pendingInvoice.settleMonth);
    };

    onCancel = () => {
        this.setState({
            showApprovalDialog: false,
        });
    };

    render() {
        const { intl } = this.props;
        const rs = { Ok: intl.formatMessage({ id: 'OK' }), Cancel: intl.formatMessage({ id: 'CANCEL' }) };

        const columns = [
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_MONTH' }),
                dataIndex: 'settleMonth',
                width: 80,
                render: (val) => <YearMonth value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TRACKING_CODE' }),
                dataIndex: 'trackingCode',
                width: 110,
            },
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_TOTAL_AMOUNT' }),
                dataIndex: 'totalAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: (
                    <div>
                        <span>{intl.formatMessage({ id: 'SETTLEMENT_COL_WITHHOLDING_TAX' })}</span>
                        <Tooltip
                            title="수익에 부과되는 사업소득세(3%) 및 주민세(사업소득세의 10%)를 합친 금액입니다.​"
                            trigger="click"
                        >
                            <Icon type="question-circle" style={{ marginLeft: '0.5em', fontSize: '1.2em' }} />
                        </Tooltip>
                    </div>
                ),
                align: 'right',
                render: (val, record) => <Currency value={record.incomeTax + record.localTax} />,
            },
            {
                title: (
                    <div>
                        <span>{this.props.intl.formatMessage({ id: 'TAX_INVOICE_COL_VAT' })}</span>
                        <Tooltip title={intl.formatMessage({ id: 'VAT_TIPS' })} trigger="click">
                            <Icon type="question-circle" style={{ marginLeft: '0.5em', fontSize: '1.2em' }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'vat',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_AMOUNT_TO_PAY' }),
                dataIndex: 'payAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_ISSUE_DATE' }),
                dataIndex: 'issueDate',
                align: 'center',
                render: (val) => <IntlDate value={val} />,
            },
            {
                title: (
                    <React.Fragment>
                        <span>{intl.formatMessage({ id: 'TAX_INVOICE_COL_PAYMENT_DATE' })}</span>
                        <Tooltip title={intl.formatMessage({ id: 'PAYMENT_DATE_TIPS' })} trigger="click">
                            <Icon type="question-circle" style={{ marginLeft: '0.5em', fontSize: '1.2em' }} />
                        </Tooltip>
                    </React.Fragment>
                ),
                dataIndex: 'payDate',
                align: 'center',
                render: (val) => <IntlDate value={val} />,
            },

            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_STATUS' }),
                dataIndex: 'invStatus',
                align: 'center',
                width: 110,
                render: (invStatus, record) => {
                    const { intl } = this.props;
                    switch (invStatus) {
                        case 'WAITING':
                            if (!record.invSeq) {
                                return null;
                            }
                            return record.details > 0 ? (
                                <Button
                                    type="primary"
                                    ghost
                                    className="sm"
                                    onClick={() => this.openInvoiceApproveWindow(record.settleMonth)}
                                >
                                    {intl.formatMessage({ id: 'APPROVE' })}
                                </Button>
                            ) : (
                                <span className="color-danger">
                                    {intl.formatMessage({ id: 'PAYOUT_STATUS_CARRY_OVER' })}
                                </span>
                            );
                        case 'APPROVED':
                            return <span className="color-accent">{record.invStatusString || invStatus}</span>;
                        default:
                            return record.invoice && record.invoice.errors ? (
                                <Tooltip title={record.invoice.errors}>
                                    <span>{record.invStatusString || invStatus}</span>
                                </Tooltip>
                            ) : (
                                <span>{record.invStatusString || invStatus}</span>
                            );
                    }
                },
            },
            {
                title: intl.formatMessage({ id: 'TAX_INVOICE_COL_VIEW_INVOICE' }),
                dataIndex: 'invoice',
                render: (val) =>
                    val &&
                    val.invWebUrl && (
                        <a href={val.invWebUrl} target="_blank">
                            {intl.formatMessage({ id: 'TAX_INVOICE_COL_VIEW_INVOICE' })}
                        </a>
                    ),
            },
            {
                title: intl.formatMessage({ id: 'PAY_STATUS' }),
                dataIndex: 'payStatus',
                render: (payStatus, record) => {
                    return <PayStatus payStatus={payStatus} payStatusString={record.payStatusString} />;
                },
            },
            {
                title: '',
                dataIndex: 'details',
                width: 80,
                align: 'center',
                render: (val, record) => {
                    return (
                        <Button
                            disabled={val === 0}
                            className="sm"
                            onClick={() => this.viewPayoutSettlements(record.trackingCode, record.settleMonth)}
                        >
                            {intl.formatMessage({ id: 'VIEW_DETAILS' })}
                        </Button>
                    );
                },
            },
        ];

        return (
            <Fragment>
                <Confirm
                    className="pending-invoice nowrap"
                    description={
                        <div>
                            <div className="tip-message">
                                {intl.formatMessage({ id: 'TAX_INVOICE_PENDING_INVOICE_MSG' })}
                            </div>
                            <div className="align-left">
                                <span>
                                    {intl.formatMessage({ id: 'TAX_INVOICE_COL_BILL_NUMBER' })}:{' '}
                                    <span className="color-accent ft-bold ft-16">
                                        {(this.state.pendingInvoice || {}).invSeq}
                                    </span>
                                </span>
                            </div>
                            <div className="align-left">
                                <span>
                                    {intl.formatMessage({ id: 'TAX_INVOICE_COL_ISSUE_DATE' })}:{' '}
                                    <IntlDate value={(this.state.pendingInvoice || {}).issueDate} />
                                </span>
                            </div>
                        </div>
                    }
                    mask={true}
                    maskClosable={false}
                    visible={this.state.showApprovalDialog}
                    rs={rs}
                    onOk={this.onApprove}
                    onCancel={this.onCancel}
                />

                <DataTable
                    rowKey="monthlySettlementPayoutId"
                    columns={columns}
                    getData={Api.fetchPayoutReport}
                    onLoaded={this.handleDataLoaded}
                />
            </Fragment>
        );
    }
}

export default injectIntl(withRouter(PayoutReportMixed));
