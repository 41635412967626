import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CardPage } from '../../components';
import { Consts } from '../../common';
import {
    IndividualPayerInformationFieldset,
    CompanyInformationFieldset,
    ContactFieldset,
    BankAccountFieldset,
    PaymentModificationTips,
    DocumentFieldset,
    PiiCollectionAgreementFieldset,
} from '../components';
import MemberInfo from '../../registration/components/MemberInfo';
import { fetchEditOtherIndustriesPaymentInitData, updateOtherIndustriesPayment, validateRRN } from '../actions';

import SettlementApi from '../../settlement/api';
import PiiCollectionLaterTips from '../components/PiiCollectionLaterTips';

const mapStateToProps = (state) => ({
    bankNameAndCodes: state.payment.bankNameAndCodes,
    data: state.payment.data,
    memberInfo: state.payment.memberInfo,
    piiInputStatus: state.payment.piiInputStatus || Consts.PiiInputStatus.Disabled,
    loading: state.payment.loading,
    affiliateType: state.affiliate.affiliateType,
    activated: state.affiliate.activateStatus === Consts.ActivateStatus.Activated,
    maintainingEnabled: !!state.config.maintainingEnabled,
});

const mapDispatchToProps = {
    validateRRN,
    fetchEditOtherIndustriesPaymentInitData,
    updateOtherIndustriesPayment,
};

class EditOtherIndustriesPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { fetchEditOtherIndustriesPaymentInitData, form } = this.props;

        fetchEditOtherIndustriesPaymentInitData &&
            fetchEditOtherIndustriesPaymentInitData().then(() => {
                SettlementApi.getDocStatuses().then((response) => {
                    const { data: { hasBrcDoc, hasBankbookDoc } = {} } = response.data;

                    let brnFileList = [];
                    let bankbookFileList = [];
                    if (hasBrcDoc) {
                        brnFileList = [{ uid: '-1', status: 'done', url: `/api/v1/payment/image/brn` }];
                    }
                    if (hasBankbookDoc) {
                        bankbookFileList = [{ uid: '-2', status: 'done', url: `/api/v1/payment/image/bank` }];
                    }
                    this.setState({ docStatusesChecked: true }, () => {
                        form.setFieldsValue({ brnFileList, bankbookFileList });
                    });
                });
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, affiliateType, updateOtherIndustriesPayment, history } = this.props;

        form.validateFields({}, (err, values) => {
            if (!err) {
                values.affiliateType = affiliateType;
                if (values.rrn) {
                    values.rrn = values.rrn.raw;
                }
                values.address = values.address || '';
                values.mobile = values.mobile || '';

                if (updateOtherIndustriesPayment) {
                    updateOtherIndustriesPayment(values).then(() => {
                        history.push('/affiliate');
                    });
                }
            }
        });
    };

    render() {
        const {
            intl,
            form,
            loading,
            maintainingEnabled,
            activated,
            bankNameAndCodes,
            data,
            memberInfo,
            piiInputStatus,
            validateRRN,
        } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div className="edit-other-industries-payment">
                <CardPage loading={loading}>
                    <Form layout="vertical" hideRequiredMark={false}>
                        {piiInputStatus === Consts.PiiInputStatus.Completed && (
                            <div className="mb24">
                                <PaymentModificationTips />
                            </div>
                        )}
                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <React.Fragment>
                                {(piiInputStatus === Consts.PiiInputStatus.Opened ||
                                    piiInputStatus === Consts.PiiInputStatus.Completed) && (
                                    <MemberInfo
                                        className="mb32"
                                        memberInfo={memberInfo}
                                        withNameSync={true}
                                        piiInputStatus={piiInputStatus}
                                    />
                                )}
                                <IndividualPayerInformationFieldset
                                    form={form}
                                    validateRRN={validateRRN}
                                    data={data}
                                    disabled={piiInputStatus !== Consts.PiiInputStatus.Opened}
                                />
                            </React.Fragment>
                        )}
                        <ContactFieldset
                            mode="edit"
                            form={form}
                            data={data}
                            disabled={maintainingEnabled}
                            includeContactName={false}
                        />

                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <CompanyInformationFieldset
                                form={form}
                                data={data}
                                disabled={maintainingEnabled || piiInputStatus !== Consts.PiiInputStatus.Opened}
                            />
                        )}
                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <React.Fragment>
                                <BankAccountFieldset
                                    type="BUSINESS"
                                    form={form}
                                    data={data}
                                    disabled={maintainingEnabled || piiInputStatus !== Consts.PiiInputStatus.Opened}
                                    banks={bankNameAndCodes}
                                />

                                <DocumentFieldset
                                    form={form}
                                    disabled={maintainingEnabled || piiInputStatus !== Consts.PiiInputStatus.Opened}
                                />
                            </React.Fragment>
                        )}
                        {piiInputStatus === Consts.PiiInputStatus.Opened && (
                            <PiiCollectionAgreementFieldset form={form} />
                        )}

                        {piiInputStatus === Consts.PiiInputStatus.Disabled && (
                            <PiiCollectionLaterTips className="mb32" />
                        )}
                        {!maintainingEnabled && (
                            <div className="button-bar align-center">
                                <Button size="large" type="primary" htmlType="button" onClick={this.handleSubmit}>
                                    {intl.formatMessage({ id: 'SAVE' })}
                                </Button>
                            </div>
                        )}
                    </Form>
                </CardPage>
            </div>
        );
    }
}

export default Form.create()(
    injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOtherIndustriesPayment)))
);
