import React from 'react';
import regionsConfig from '../../i18n/regions';

const RegionContext = React.createContext();
const RegionProvider = ({ children, regionKey }) => {
    
    return regionsConfig[regionKey] && (
        <RegionContext.Provider
            value={regionsConfig[regionKey]}
        >
            {children}
        </RegionContext.Provider>
    );
};
export { RegionContext, RegionProvider };
