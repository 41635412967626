import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col, Input } from 'antd';
import EditIcon from 'react-feather/dist/icons/edit-3';
import CloseIcon from 'react-feather/dist/icons/x';

import { PhoneNumberInput, usePhoneNumberValidator } from './PhoneNumber';
import { requiredMessage, invalidMessage } from '../../common';

export default function ContactFieldset({ form, data = {}, disabled, includeContactName, mode = 'create' }) {
    const intl = useIntl();
    const [editing, setEditing] = useState(false);
    const phoneNumberValidator = usePhoneNumberValidator();
    const editPhoneNumber = () => {
        setEditing(true);
    };
    const cancelEditPhoneNumber = () => {
        setEditing(false);
    };
    const CancelButton = () => {
        return <CloseIcon size={14} style={{ cursor: 'pointer' }} onClick={() => cancelEditPhoneNumber()} />;
    };
    return (
        <fieldset>
            <legend>{intl.formatMessage({ id: 'PAYMENT_SECTION_CONTACT' })}</legend>
            <Row gutter={20}>
                {includeContactName !== false ? (
                    <Col span={24} sm={12}>
                        <Form.Item label={intl.formatMessage({ id: 'NAME' })}>
                            {form.getFieldDecorator('contactName', {
                                initialValue: data.contactName,
                                rules: [
                                    {
                                        required: true,
                                        message: requiredMessage(intl, 'NAME'),
                                    },
                                ],
                            })(<Input maxLength={30} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                ) : null}
                <Col span={24} sm={12}>
                    <Row gutter={8}>
                        <Form.Item
                            label={intl.formatMessage({ id: 'PAYMENT_PHONE_NUMBER' })}
                            style={{ display: mode === 'edit' && !editing ? 'block' : 'none' }}
                        >
                            <Input
                                value={data.phone}
                                readOnly={true}
                                addonAfter={
                                    <EditIcon
                                        size={14}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => editPhoneNumber()}
                                    />
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            label={intl.formatMessage({ id: 'PAYMENT_PHONE_NUMBER' })}
                            style={{ display: mode === 'create' || editing ? 'block' : 'none' }}
                        >
                            {form.getFieldDecorator('phone', {
                                rules:
                                    mode === 'create' || editing
                                        ? [
                                              {
                                                  required: true,
                                                  message: requiredMessage(intl, 'PAYMENT_PHONE_NUMBER'),
                                              },
                                              {
                                                  validator: (_, value, callback) => {
                                                      if (!value || phoneNumberValidator(value)) {
                                                          return callback();
                                                      }
                                                      callback(invalidMessage(intl, 'PAYMENT_PHONE_NUMBER'));
                                                  },
                                              },
                                          ]
                                        : [],
                            })(<PhoneNumberInput addonAfter={mode === 'edit' ? <CancelButton /> : null} />)}
                        </Form.Item>
                    </Row>
                </Col>

                <Col span={24} sm={12}>
                    <Form.Item label={intl.formatMessage({ id: 'PAYMENT_EMAIL' })}>
                        {form.getFieldDecorator('email', {
                            initialValue: data.email,
                            rules: [
                                { required: true, message: requiredMessage(intl, 'PAYMENT_EMAIL') },
                                { type: 'email', message: invalidMessage(intl, 'PAYMENT_EMAIL') },
                            ],
                        })(
                            <Input
                                maxLength={100}
                                disabled={disabled}
                                autoComplete="off"
                                placeholder={intl.formatMessage({ id: 'PAYMENT_EMAIL_PLACEHOLDER' })}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </fieldset>
    );
}
