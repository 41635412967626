import React from 'react';
import { notification } from 'antd';
import axios from 'axios';
import * as echarts from 'echarts/core';
import { auth } from './auth';

window.axios = axios;

export function setup(store) {
    axios.interceptors.request.use((config) => {
        
        // set token header
        if (!window.xToken) {
            const tokenRegExp = /(?:^|\s)AFATK=([^;]+)(?:;|$)/;
            const tokenMatches = document.cookie.match(tokenRegExp);
            if (tokenMatches) {
                window.xToken = tokenMatches[1];
            }
        }
        if (window.xToken) {
            config.headers['x-token'] = window.xToken;
        }
        // set member srl header
        if (!window.xMemberSrl) {
            const memberSrlRegExp = /(?:^|\s)member_srl=([^;]+)(?:;|$)/;
            const memberSrlMatches = document.cookie.match(memberSrlRegExp);
            if (memberSrlMatches) {
                window.xMemberSrl = memberSrlMatches[1];
            }
        }
        if (window.xMemberSrl) {
            config.headers['x-member-srl'] = window.xMemberSrl;
        }

        let currentSubId = sessionStorage.getItem('currentSubId');
        if (currentSubId) {
            config.headers['x-sub-id'] = currentSubId;
        }
        return config;
    });
    axios.interceptors.response.use(
        (res) => {
            const { rCode, rMessage } = res.data;
            // session timeout
            if (rCode === '600' || rCode === '601') {
                store.dispatch({ type: 'SESSION_TIMEOUT', cmd: rCode === '600' ? 'login' : 'reload' });
                store.dispatch({ type: 'UPDATE_AFFILIATE', payload: {} });
            } else if (rCode === '403') {
                return new Promise((resolve, reject) => {
                    store.dispatch(
                        auth({
                            onAuthSuccess: () => resolve(axios(res.config)),
                            onAuthCancelled: () => reject({}),
                        })
                    );
                });
            } else if (rCode === '429') {
                notification.warn({
                    message: '에러',
                    description: <div dangerouslySetInnerHTML={{ __html: rMessage }} />,
                });
                return Promise.reject({});
            }
            const contentType = res.headers['content-type'] || '';
            if (contentType.includes('json')) {
                if (rCode !== '0') {
                    return Promise.reject(res);
                }
            }
            return res;
        },
        (err) => {
            let notice = null;
            if (err && err.response && err.response.status >= 400) {
                notice = {
                    message: 'Error',
                    description: err.response.data || err.response.statusText,
                };
            } else if (err && err.message) {
                notice = {
                    message: 'Error',
                    description: err.message,
                };
            }
            if (notice) {
                notification.error(notice);
            }

            return Promise.reject((err && err.response) || {});
        }
    );

    echarts.registerTheme('affiliate', {
        legend: {
            x: 'center',
            y: 'top',
            textStyle: {
                fontWeight: 'bold',
            },
        },
        tooltip: {
            axisPointer: {
                lineStyle: {
                    color: '#008C00',
                },
            },
        },
        categoryAxis: {
            axisLabel: {
                color: '#808286',
                fontWeight: 200,
                fontFamily: 'NotoSansCJKKr',
            },
            axisLine: {
                lineStyle: {
                    color: '#cfd0d2',
                },
            },
            axisTick: {
                show: false,
            },
        },
        valueAxis: {
            nameTextStyle: {
                fontSize: 12,
                fontFamily: 'NotoSansCJKKr',
                color: '#808286',
                // padding: [0, 68, 0, 0],
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontFamily: 'NotoSansCJKKr',
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#DFE0E2',
                    width: 1,
                },
            },
        },
        line: {
            hoverAnimation: false,
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {},
            emphasis: {
                itemStyle: {
                    borderWidth: 6,
                    borderColor: 'rgba(140, 80, 163, 0.2)',
                },
            },
        },
    });
}
