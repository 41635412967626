import React from 'react';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import { MetricList } from './Metric';

export default injectIntl(function ({ intl, className, customLabel, metric }) {
    return (
        <span className={cx('metric', className)}>
            {customLabel || intl.formatMessage({ id: MetricList.find((item) => item.id == metric).key })}
        </span>
    );
});
