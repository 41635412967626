import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TrackerContext } from './Tracker';
import FooterLogo from './logos/coupang_partners_white.png';
import Kakao from './logos/kakao.png';
import Facebook from './logos/facebook.png';
import Youtube from './logos/youtube.png';
import Naver from './logos/naver.png';
import Instagram from './logos/instagram.png';

import './Footer.css';

export default function Footer() {
    const intl = useIntl();
    const history = useHistory();
    const { trackEvent } = useContext(TrackerContext);
    const { affiliate } = useSelector((state) => state);
    const coupangCEO = useSelector((state) => state.config.coupangCEO);

    return (
        <div className="footer-container">
            <footer className="app-footer">
                <ul className="app-footer-content">
                    <li>
                        <div style={{ marginBottom: 15 }}>
                            <img src={FooterLogo} alt="coupang partners" width={90} />
                        </div>
                    </li>
                    <li>
                        <div>
                            {intl.formatMessage({ id: 'COMPANY_CEO_LABEL' })}: {coupangCEO || '강한승, 박대준'}
                        </div>
                        <div>{intl.formatMessage({ id: 'COMPANY_ADDRESS' })}</div>
                    </li>
                    <li>
                        <div>{intl.formatMessage({ id: 'COMPANY_BRN' })}</div>
                        <div>
                            {intl.formatMessage({ id: 'COMPANY_MAILING_ADDRESS' })} /{' '}
                            <a
                                href="http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=1208800767"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {intl.formatMessage({ id: 'BUSINESS_REGISTRATION_INFO' })}
                            </a>
                        </div>
                        <a href="https://privacy.coupang.com/ko/center/coupang/" target="_blank">
                            <b>{intl.formatMessage({ id: 'PRIVACY_POLICIES' })}</b>
                        </a>
                        <div>Copyright © Coupang Corp. 2018-2020 All Rights Reserved.</div>
                    </li>
                    <li>
                        <div>
                            {intl.formatMessage({ id: 'CONCAT_US' })}{' '}
                            <a
                                onClick={() => {
                                    if (affiliate) {
                                        history.push('/help/voc/create');
                                    } else {
                                        window.location = 'mailto:partners@coupang.com';
                                    }
                                }}
                            >
                                partners@coupang.com​
                            </a>
                        </div>
                        <div className="support-channels">
                            <a
                                href="https://pf.kakao.com/_TTxlwj"
                                title="Kakao"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'kakao' })}
                            >
                                <img src={Kakao} alt="" />
                            </a>
                            <a
                                href="https://www.facebook.com/Partners.Coupang"
                                title="Facebook"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'facebook' })}
                            >
                                <img src={Facebook} alt="" />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCwJBfcQVt4BtXgut384AV6w"
                                title="YouTube"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'youtube' })}
                            >
                                <img src={Youtube} alt="" />
                            </a>
                            <a
                                href="https://blog.naver.com/partners_official"
                                title="Naver"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'naver' })}
                            >
                                <img src={Naver} alt="" />
                            </a>
                            <a
                                href="https://www.instagram.com/coupangpartners_official"
                                title="Instagram"
                                rel="noopener noreferrer"
                                target="_blank"
                                onClick={() => trackEvent({ action: 'communication_channel', value: 'instagram' })}
                            >
                                <img src={Instagram} alt="" />
                            </a>
                        </div>
                    </li>
                </ul>
            </footer>
        </div>
    );
}
