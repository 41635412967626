import React, { useState, useRef, useContext } from 'react';
import { Button, Input } from 'antd';

import './DataTableInputFilter.css';
import { LocaleContext } from '../Locale';

export default function DataTableInputFilter({ inputProps, setSelectedKeys, clearFilters, confirm }) {
    const { antLocaleResource } = useContext(LocaleContext);
    const {
        Table: { filterConfirm, filterReset },
    } = antLocaleResource;

    const inputRef = useRef();
    const [text, setText] = useState('');
    const handleTextChange = (e) => {
        const term = e.target.value.trim();
        setText(term);
        setSelectedKeys([term]);
    };
    const handleReset = () => {
        setText('');
        clearFilters();
    };

    const handleSearch = () => {
        confirm();
    };
    return (
        <div className="dt-input-filter-drop">
            <div className="input-line">
                <Input
                    autoComplete="off"
                    {...inputProps}
                    ref={inputRef}
                    value={text}
                    onChange={handleTextChange}
                    onPressEnter={handleSearch}
                />
            </div>
            <div className="buttons">
                <Button size="small" icon="search" type="primary" onClick={handleSearch}>
                    {filterConfirm}
                </Button>
                <Button size="small" onClick={handleReset}>
                    {filterReset}
                </Button>
            </div>
        </div>
    );
}
