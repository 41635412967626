import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedNumber } from 'react-intl';
import cx from 'classnames';
import { DataTable, Currency, YearMonth, IntlDate, DataTableInputFilter, TextEllipsis } from '../../components';
import CommissionTypeDisplay from './CommissionTypeDisplay';

import Api from '../api';

class SettlementDetailsDataTable extends PureComponent {
    static propTypes = {
        trackingCode: PropTypes.string.isRequired,
        settleMonth: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.subIdFilterRef = React.createRef();
    }

    render() {
        const { intl, trackingCode, settleMonth } = this.props;

        const columns = [
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_MONTH' }),
                dataIndex: 'calMonth',
                width: 80,
                render: (val) => <YearMonth value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TRACKING_CODE' }),
                dataIndex: 'trackingCode',
                width: 110,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_SUBID' }),
                dataIndex: 'subId',
                filterMultiple: false,
                width: 120,
                render: (val) => {
                    return <TextEllipsis value={val} maxWidth={130} />;
                },
                filterDropdown: ({ ...filterProps }) => {
                    return (
                        <DataTableInputFilter
                            ref={this.subIdFilterRef}
                            {...filterProps}
                            inputProps={{ maxLength: 200 }}
                        />
                    );
                },
                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        const { current } = this.subIdFilterRef;
                        if (current && current.selectInput) {
                            setTimeout(() => {
                                current.selectInput();
                            }, 0);
                        }
                    }
                },
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_TYPE' }),
                dataIndex: 'detailType',
                render: (val, record) => <CommissionTypeDisplay value={record} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_ORDER' }),
                dataIndex: 'orderCount',
                render: (val) => <FormattedNumber value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_CANCEL' }),
                dataIndex: 'cancelCount',
                render: (val) => <FormattedNumber value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_PURCHASE_AMOUNT' }),
                dataIndex: 'totalPurchaseAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_CANCEL_AMOUNT' }),
                dataIndex: 'totalCancelAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_TOTAL_AMOUNT' }),
                dataIndex: 'totalActualPaymentAmt',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_COMMISSION' }),
                dataIndex: 'totalCommission',
                align: 'right',
                render: (val) => <Currency value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_BLOCKED' }),
                dataIndex: 'blocked',
                align: 'center',
                width: 95,
                render: (val) => {
                    // return val && <Icon type="stop" style={{ marginRight: 5, color: '#f56112' }} />;
                    return (
                        <span className={cx({ 'color-danger': val })}>
                            {intl.formatMessage({ id: val ? 'YES' : 'NO' })}
                        </span>
                    );
                },
                filterMultiple: false,
                filters: [
                    { text: intl.formatMessage({ id: 'YES' }), value: 1 },
                    { text: intl.formatMessage({ id: 'NO' }), value: 0 },
                ],
            },

            {
                title: intl.formatMessage({ id: 'SETTLEMENT_DETAILS_COL_PAYOUT_DATE' }),
                dataIndex: 'payoutDate',
                align: 'center',
                render: (val) => <IntlDate value={val} />,
            },
        ];

        const extraFilters = [
            { column: 'trackingCode', operator: 'EQUAL', value: [trackingCode] },
            { column: 'settleMonth', operator: 'EQUAL', value: [settleMonth] },
        ];

        return (
            <DataTable
                rowKey="settlementDetailId"
                columns={columns}
                extraFilters={extraFilters}
                getData={Api.fetchSettlementDetailsReport}
            />
        );
    }
}

export default injectIntl(SettlementDetailsDataTable);
