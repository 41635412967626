import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, notification } from 'antd';
import { withRouter } from 'react-router';
import { WorkspacePage, CpRow, DataTable, RsLabel, confirmHoc, Copy2Clipboard, IntlDate } from '../../components';
import { TrackerContext } from '../../common';
import { DynamicWidgetCode } from '../components';
import DynamicWidgetPassbackSwitch from '../components/DyanmicWidgetPassbackSwitch';
import HomeIcon from 'react-feather/dist/icons/home';
import CopyIcon from 'react-feather/dist/icons/copy';
import EditIcon from 'react-feather/dist/icons/edit';
import RemoveIcon from 'react-feather/dist/icons/trash-2';
import Api from '../api';

const DeleteConfirm = confirmHoc(RemoveIcon);

class WidgetInstanceList extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    handleOnCreate = () => {
        const { history } = this.props;
        this.context.trackEvent({ action: 'create_widget_inst' });
        history.push('/affiliate/ws/dynamic-widget');
    };

    handleEditWidgetInstance = (id) => {
        const { history } = this.props;
        this.context.trackEvent({ action: 'edit_widget_inst' });
        history.push(`/affiliate/ws/dynamic-widget/${id}`);
    };

    handleDeleteWidgetInstance = (id) => {
        this.context.trackEvent({ action: 'delete_widget_inst' });
        
        Api.deleteWidgetInstance(id)
            .then((res) => {
                if (this.tableRef && this.tableRef.current) {
                    this.tableRef.current.refresh();
                }
            })
            .catch((err) => {
                if (err && err.data && err.data.rMessage) {
                    const { intl } = this.props;
                    notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: err.data.rMessage });
                }
            });
    };

    handleDynamiceCodeCopied = (widgetInstanceId) => {
        this.context.trackEvent({ action: 'copy_dynamic_widget_code', value: widgetInstanceId.toString() });
    };

    render() {
        const { intl, loading, trackingCode, currentSubId, config } = this.props;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_PL_DYNAMIC_BANNER' }) },
        ];

        const columns = [
            {
                title: intl.formatMessage({ id: 'ID' }),
                dataIndex: 'widgetInstanceId',
                sorter: true,
            },
            {
                title: intl.formatMessage({ id: 'WIDGET_LIST_COL_NAME' }),
                dataIndex: 'widgetInstanceName',
                sorter: true,
            },
            {
                title: intl.formatMessage({ id: 'WIDGET_LIST_COL_TYPE' }),
                dataIndex: 'dataType',
                render(val, record) {
                    return <RsLabel text={val} />;
                },
            },
            {
                title: intl.formatMessage({ id: 'WIDGET_LIST_COL_DATASET_NAME' }),
                dataIndex: 'datasetName',
            },
            {
                title: intl.formatMessage({ id: 'CREATED_DATE' }),
                dataIndex: 'createdDate',
                render: (val) => <IntlDate value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'LAST_MODIFIED' }),
                dataIndex: 'modifiedDate',
                render: (val) => <IntlDate value={val} />,
            },
        ];

        if (config.passbackEnabled) {
            columns.push({
                title: intl.formatMessage({ id: 'PASSBACK' }),
                dataIndex: 'passback',
                align: 'center',
                render: (_, record) => {
                    return <DynamicWidgetPassbackSwitch widget={record} />;
                },
            });
        }

        columns.push({
            title: intl.formatMessage({ id: 'WIDGET_LIST_COL_ACTIONS' }),
            width: 160,
            align: 'center',
            render: (val, record) => {
                const { intl } = this.props;
                return (
                    <React.Fragment>
                        <DynamicWidgetCode
                            widgetInstanceId={record.widgetInstanceId}
                            trackingCode={trackingCode}
                            subId={currentSubId}
                            width={record.width}
                            height={record.height}>
                            {(code) => (
                                <Copy2Clipboard
                                    content={code}
                                    onCopied={() => this.handleDynamiceCodeCopied(record.widgetInstanceId)}>
                                    <CopyIcon
                                        size={18}
                                        className="clickable"
                                        color="#aeb0b4"
                                        style={{ marginRight: 30 }}></CopyIcon>
                                </Copy2Clipboard>
                            )}
                        </DynamicWidgetCode>
                        <EditIcon
                            size={18}
                            className="clickable"
                            color="#aeb0b4"
                            style={{ marginRight: 30 }}
                            onClick={() => this.handleEditWidgetInstance(record.widgetInstanceId)}
                        />

                        <DeleteConfirm
                            size={18}
                            className="clickable"
                            color="#aeb0b4"
                            confirmOptions={{
                                title: intl.formatMessage({ id: 'WIDGET_LIST_DEL_CONFIRM_TITLE' }),
                                description: (
                                    <FormattedMessage
                                        id="WIDGET_LIST_DEL_CONFIRM_DESCRIPTION"
                                        values={{ name: record.widgetInstanceName }}
                                    />
                                ),
                                contentAlignment: 'left',
                            }}
                            onOk={() => this.handleDeleteWidgetInstance(val.widgetInstanceId)}
                        />
                    </React.Fragment>
                );
            },
        });

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                className=""
                title={intl.formatMessage({ id: 'HEADER_PL_DYNAMIC_BANNER' })}
                subTitle={intl.formatMessage({ id: 'WIDGET_LIST_SUB_TITLE' })}
                loading={loading}>
                <CpRow>
                    <div className="align-right" style={{ marginBottom: 15 }}>
                        <Button
                            type="primary"
                            className="lg"
                            style={{ minWidth: 160 }}
                            icon="plus"
                            onClick={this.handleOnCreate}>
                            {intl.formatMessage({ id: 'WIDGET_LIST_CREATE' })}
                        </Button>
                    </div>
                    <DataTable
                        className="widget-instance-table"
                        onRef={ref => this.tableRef.current = ref}
                        rowKey="widgetInstanceId"
                        columns={columns}
                        getData={Api.getWidgetInstances}
                    />
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    withRouter(
        connect((state) => ({
            trackingCode: (state && state.affiliate && state.affiliate.affiliateCode) || '',
            config: state.config,
            currentSubId: state.subId.currentSubId,
        }))(WidgetInstanceList)
    )
);
