import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

import { RegionContext } from '../Region';
import localesConfig from '../../i18n/locales';

const getLocaleConfig = (key) => {
    return localesConfig.find((item) => item.key === key) || localesConfig[0];
};

const loadMessages = (locale) => {
    const localeConfig = getLocaleConfig(locale);
    return Promise.all([
        import(`../../i18n/${localeConfig.key}`),
        import(`antd/lib/locale-provider/${localeConfig.ant}`),
        localeConfig.moment ? import(`moment/locale/${localeConfig.moment}`) : Promise.resolve(),
    ]);
};

const LocaleContext = React.createContext();
const LocaleProvider = ({ children }) => {
    const regionConfig = useContext(RegionContext);
    const defaultLocaleConfig = getLocaleConfig(regionConfig.lang);
    const [locale, setLocale] = useState(defaultLocaleConfig.key);
    const [currency, setCurrency] = useState(defaultLocaleConfig.currency);
    const [localeResource, setLocaleResource] = useState(null);
    const [antLocaleResource, setAntLocaleResource] = useState(null);
    useEffect(() => {
        loadMessages(locale).then(([m1, m2]) => {
            const localeConfig = getLocaleConfig(locale);
            setCurrency(localeConfig.currency);
            setLocaleResource(m1.default);
            setAntLocaleResource(m2.default);
            moment.locale(localeConfig.moment);
        });
    }, [locale]);
    return localeResource && antLocaleResource ? (
        <LocaleContext.Provider
            key={locale}
            value={{
                currency,
                locale,
                locales: localesConfig.map((item) => item.key),
                localeResource,
                antLocaleResource,
                setLocale,
            }}
        >
            {children}
        </LocaleContext.Provider>
    ) : null;
};
export { LocaleContext, LocaleProvider };
