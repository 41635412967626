import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Checkbox, Input, Radio } from 'antd';
import { CardPage, CpRow, WorkspacePage } from '../../components';

import { AffiliateTypes } from '../../common/consts';
import { unsubscribe } from '../actions';
import './Unsubscribe.less';
import Api from '../api';

const Reasons = {
    Others: '기타',
};

class Unsubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checking: true,
            reason: null,
            otherReasonText: '',
            payout: undefined,
            blockPayout: 0,
            unsubscribeReasons: [],
            doubleChecked: false,
        };
    }

    onReasonChange = (e) => {
        this.setState({
            reason: e.target.value,
            otherReasonText: e.target.value !== Reasons.Others ? '' : this.state.otherReasonText,
        });
    };

    onOtherReasonChanged = (e) => {
        this.setState({
            otherReasonText: e.target.value,
        });
    };

    onPayoutChange = (e) => {
        this.setState({
            payout: e.target.value,
        });
    };

    onDoubleCheckedChange = (e) => {
        this.setState({ doubleChecked: e.target.checked });
    };

    onUnsubscribe = () => {
        const { unsubscribe } = this.props;
        if (unsubscribe) {
            unsubscribe(this.state.reason, this.state.otherReasonText, this.state.payout);
        }
    };

    componentDidMount() {
        Api.checkAffiliateStatusForDeactivation().then((response) => {
            const { rCode, data } = response.data;
            if (rCode === '0') {
                const { blockPayout, unsubscribeReasons } = data;
                this.setState({ blockPayout, unsubscribeReasons, payout: !blockPayout, checking: false });
            }
        });
    }

    render() {
        const { intl, loading, affiliate } = this.props;
        const radioStyle = {
            display: 'block',
        };
        const { checking, payout, blockPayout, unsubscribeReasons, doubleChecked } = this.state;
        const reasonFilled =
            (this.state.reason && this.state.reason !== Reasons.Others) ||
            (this.state.reason === Reasons.Others && this.state.otherReasonText);

        return (
            <WorkspacePage
                loading={checking}
                mini={true}
                withHome={true}
                className="unsubscribe-affiliate is-flex bg-grey"
            >
                <CpRow style={{ maxWidth: 840 }}>
                    <h1 className="page-title-secondary">{intl.formatMessage({ id: 'UNSUBSCRIBE_TITLE' })}</h1>
                    <CardPage loading={loading}>
                        <section>
                            <h3>{intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE' })}</h3>
                            <ul>
                                <li>{intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE_MSG_1' })}</li>
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE_MSG_2' }),
                                    }}
                                ></li>
                                <li>{intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE_MSG_3' })}</li>
                                <li>{intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE_MSG_4' })}</li>
                                <li>{intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE_MSG_5' })}</li>
                                <li>{intl.formatMessage({ id: 'UNSUBSCRIBE_NOTICE_MSG_6' })}</li>
                            </ul>
                        </section>

                        <section>
                            <h3>{intl.formatMessage({ id: 'UNSUBSCRIBE_REASON' })}</h3>
                            <Radio.Group
                                size="large"
                                style={{ marginBottom: 14 }}
                                value={this.state.reason}
                                onChange={this.onReasonChange}
                            >
                                {unsubscribeReasons.map((item) => (
                                    <Radio style={radioStyle} value={item}>
                                        {item}
                                    </Radio>
                                ))}
                            </Radio.Group>

                            <Input.TextArea
                                rows={4}
                                maxLength={200}
                                disabled={this.state.reason !== Reasons.Others}
                                placeholder={intl.formatMessage({ id: 'UNSUBSCRIBE_REASON_PLACEHOLDER' })}
                                value={this.state.otherReasonText}
                                onChange={this.onOtherReasonChanged}
                            ></Input.TextArea>
                        </section>

                        <section>
                            <h3>{intl.formatMessage({ id: 'UNSUBSCRIBE_PAYOUT' })}</h3>
                            <div style={{ marginBottom: 14 }}>
                                {intl.formatMessage({ id: 'UNSUBSCRIBE_PAYOUT_MESSAGE' })}
                            </div>
                            <Radio.Group size="large" value={this.state.payout} onChange={this.onPayoutChange}>
                                <Radio value={true} disabled={!!blockPayout}>
                                    {intl.formatMessage({ id: 'YES' })}
                                </Radio>
                                <Radio value={false}>{intl.formatMessage({ id: 'NO' })}</Radio>
                            </Radio.Group>
                            {!!blockPayout && (
                                <div>
                                    {blockPayout === 1 && (
                                        <div>{intl.formatMessage({ id: 'UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_1' })}</div>
                                    )}
                                    {blockPayout === 2 && (
                                        <div>{intl.formatMessage({ id: 'UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_2' })}</div>
                                    )}
                                    {blockPayout === 3 && (
                                        <div>{intl.formatMessage({ id: 'UNSUBSCRIBE_CAN_NOT_PAYOUT_REASON_3' })}</div>
                                    )}
                                </div>
                            )}
                            {!blockPayout && !payout && affiliate.affiliateType !== AffiliateTypes.Company && (
                                <div>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: intl.formatMessage({ id: 'UNSUBSCRIBE_GIVE_UP_PAYOUT_TIPS' }),
                                        }}
                                    ></div>
                                </div>
                            )}
                        </section>

                        <section>
                            <Checkbox value={doubleChecked} onChange={this.onDoubleCheckedChange}>
                                {intl.formatMessage({ id: 'MY_ACCOUNT_UNSUBSCRIBE_CONFIRM_TIPS' })}
                            </Checkbox>
                        </section>

                        <div className="align-center">
                            <Button
                                type="primary"
                                size="large"
                                disabled={!reasonFilled || !doubleChecked}
                                onClick={this.onUnsubscribe}
                            >
                                {intl.formatMessage({ id: 'DEACTIVATE' })}
                            </Button>
                        </div>
                    </CardPage>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(connect((state) => ({ affiliate: state.affiliate }), { unsubscribe })(Unsubscribe));
