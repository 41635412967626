import React, { useState, useContext, useEffect } from 'react';
import qs from 'qs';
import { useIntl } from 'react-intl';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import HomeIcon from 'react-feather/dist/icons/home';
import { Row, Col, Card, Pagination, Spin } from 'antd';
import { WorkspacePage, CpRow, IntlDate, LocaleContext } from '../../components';
import FeatCheckerContext from '../../feat-checker/FeatCheckerContext';

import Api from '../api';
import styles from './EventList.module.less';

export default function () {
    const intl = useIntl();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const { checkFeat } = useContext(FeatCheckerContext);
    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'EVENT_LIST_TITLE' }) },
    ];
    const queryString = qs.parse(location.search.substr(1));
    const page = Number.isNaN(Number(queryString.page)) ? 1 : Number(queryString.page);
    const size = 12;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        Api.getEvents({
            page: {
                pageNumber: page - 1,
                size,
            },
        })
            .then((res) => {
                setLoading(false);
                const { rCode, data } = res.data;
                if (rCode === '0') {
                    setData(data);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }, [page]);

    useEffect(() => {
        checkFeat('events');
        // eslint-disable-next-line
    }, []);

    return (
        <WorkspacePage
            breadcrumb={breadcrumb}
            className="events"
            title={intl.formatMessage({ id: 'EVENT_LIST_TITLE' })}
        >
            <CpRow>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Spin spinning={loading}>
                            <Row type="flex" gutter={[24, 24]}>
                                {data &&
                                    data.content &&
                                    Array.isArray(data.content) &&
                                    data.content.map((item) => {
                                        return (
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} key={item.eventId}>
                                                <Card
                                                    hoverable={true}
                                                    bordered={false}
                                                    cover={
                                                        <div className={styles.cover}>
                                                            <img alt="" src={item.image} />
                                                        </div>
                                                    }
                                                    onClick={() => {
                                                        history.push(`${match.path}/${item.eventId}`);
                                                    }}
                                                    className={styles.card}
                                                >
                                                    <Card.Meta title={item.eventName} />
                                                    <div className={styles.description}>{item.description}</div>
                                                    <div className={styles.datetime}>
                                                        {(item.startDate || item.endDate) && (
                                                            <span>
                                                                <IntlDate value={item.startDate} /> ~{' '}
                                                                <IntlDate value={item.endDate} />
                                                            </span>
                                                        )}
                                                    </div>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </Spin>
                    </Col>
                    <Col span={24} className="align-right">
                        <Pagination
                            current={page}
                            total={(data && data.total) || 0}
                            showTotal={(total, range) =>
                                intl
                                    .formatMessage({ id: 'DATA_TABLE_TOTAL_ITEMS' })
                                    .replace(/\{(\d+)\}/g, (m, val) => [range[0], range[1], total][val])
                            }
                            showQuickJumper={true}
                            defaultPageSize={size}
                            onChange={(page) => {
                                history.push(`?page=${page}`);
                            }}
                        />
                    </Col>
                </Row>
            </CpRow>
        </WorkspacePage>
    );
}
